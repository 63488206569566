import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

axios.defaults.baseURL = 'https://batavia-backend.herokuapp.com/api'

export default new Vuex.Store({
  state: {
    user: null
  },
  mutations: {
    setUserData (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },

    clearUserData () {
      localStorage.removeItem('user')
      location.reload()
    }
  },
  actions: {
    login ({ commit }, credentials) {
      return axios
          .post('/login', credentials)
          .then(({ data }) => {
            commit('setUserData', data)
          })
    },

    logout ({ commit }) {
      commit('clearUserData')
      return axios
          .get('https://batavia-backend.herokuapp.com/api/logout')
          .then(() => {
            this.$router.push({ name: 'Home'})
          })
    }
  },
  getters : {
    isLogged: state => !!state.user
  },
  modules: {
  }
})
