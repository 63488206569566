import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import(/* webpackChunkName: "menu" */ '../views/Menu.vue')
  },
  {
    path: '/a-la-carte',
    name: 'ALaCarte',
    component: () => import(/* webpackChunkName: "a-la-carte" */ '../views/ALaCarte.vue')
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about-us" */ '../views/AboutUs.vue')
  },
  {
    path: '/visit-us',
    name: 'VisitUs',
    component: () => import(/* webpackChunkName: "visit-us" */ '../views/VisitUs.vue')
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },

  {
    path: '/cms/dashboard',
    name: 'CmsDashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/cms/Dashboard.vue')
  },

  {
    path: '/cms/menu',
    name: 'CmsMenu',
    component: () => import(/* webpackChunkName: "cms/menu" */ '../views/cms/menu/MenuOverview.vue')
  },
  {
    path: '/cms/menu/create',
    name: 'CmsMenuCreate',
    component: () => import(/* webpackChunkName: "cms/menu/create" */ '../views/cms/menu/MenuCreate.vue')
  },
  {
    path: '/cms/menu/show/:id',
    name: 'CmsMenuShow',
    component: () => import(/* webpackChunkName: "cms/menu/show" */ '../views/cms/menu/MenuShow.vue')
  },
  {
    path: '/cms/menu/update/:id',
    name: 'CmsMenuUpdate',
    component: () => import(/* webpackChunkName: "cms/menu/update" */ '../views/cms/menu/MenuUpdate.vue')
  },
  {
    path: '/cms/menu/delete/:id',
    name: 'CmsMenuDelete',
    component: () => import(/* webpackChunkName: "cms/menu/delete" */ '../views/cms/menu/MenuDelete.vue')
  },

  {
    path: '/cms/a-la-carte',
    name: 'CmsALaCarte',
    component: () => import(/* webpackChunkName: "cms/a-la-carte" */ '../views/cms/alacarte/ALaCarteOverview.vue')
  },
  {
    path: '/cms/a-la-carte/create',
    name: 'CmsALaCarteCreate',
    component: () => import(/* webpackChunkName: "cms/a-la-carte/create" */ '../views/cms/alacarte/ALaCarteCreate.vue')
  },
  {
    path: '/cms/a-la-carte/show/:id',
    name: 'CmsALaCarteShow',
    component: () => import(/* webpackChunkName: "cms/a-la-carte/show" */ '../views/cms/alacarte/ALaCarteShow.vue')
  },
  {
    path: '/cms/a-la-carte/update/:id',
    name: 'CmsALaCarteUpdate',
    component: () => import(/* webpackChunkName: "cms/a-la-carte/update" */ '../views/cms/alacarte/ALaCarteUpdate.vue')
  },
  {
    path: '/cms/a-la-carte/delete/:id',
    name: 'CmsALaCarteDelete',
    component: () => import(/* webpackChunkName: "cms/a-la-carte/delete" */ '../views/cms/alacarte/ALaCarteDelete.vue')
  },

  {
    path: '/cms/categories',
    name: 'CmsCategories',
    component: () => import(/* webpackChunkName: "cms/categories" */ '../views/cms/category/CategoryOverview.vue')
  },
  {
    path: '/cms/categories/create',
    name: 'CmsCategoryCreate',
    component: () => import(/* webpackChunkName: "cms/categories/create" */ '../views/cms/category/CategoryCreate.vue')
  },
  {
    path: '/cms/categories/show/:id',
    name: 'CmsCategoryShow',
    component: () => import(/* webpackChunkName: "cms/categories/show" */ '../views/cms/category/CategoryShow.vue')
  },
  {
    path: '/cms/categories/update/:id',
    name: 'CmsCategoryUpdate',
    component: () => import(/* webpackChunkName: "cms/categories/update" */ '../views/cms/category/CategoryUpdate.vue')
  },
  {
    path: '/cms/categories/delete/:id',
    name: 'CmsCategoryDelete',
    component: () => import(/* webpackChunkName: "cms/categories/delete" */ '../views/cms/category/CategoryDelete.vue')
  },

  {
    path: '/cms/entries',
    name: 'CmsEntries',
    component: () => import(/* webpackChunkName: "cms/entries" */ '../views/cms/entry/EntryOverview.vue')
  },
  {
    path: '/cms/entries/create',
    name: 'CmsEntryCreate',
    component: () => import(/* webpackChunkName: "cms/entries/create" */ '../views/cms/entry/EntryCreate.vue')
  },
  {
    path: '/cms/entries/show/:id',
    name: 'CmsEntryShow',
    component: () => import(/* webpackChunkName: "cms/entries/show" */ '../views/cms/entry/EntryShow.vue')
  },
  {
    path: '/cms/entries/update/:id',
    name: 'CmsEntryUpdate',
    component: () => import(/* webpackChunkName: "cms/entries/create/update" */ '../views/cms/entry/EntryUpdate.vue')
  },
  {
    path: '/cms/entries/delete/:id',
    name: 'CmsEntryDelete',
    component: () => import(/* webpackChunkName: "cms/entries/create/delete" */ '../views/cms/entry/EntryDelete.vue')
  },

  {
    path: '/cms/cash-count',
    name: 'CmsCashCounts',
    component: () => import(/* webpackChunkName: "cms/cash-count" */ '../views/cms/cash-count/CashCountOverview.vue')
  },
  {
    path: '/cms/cash-count/create',
    name: 'CmsCashCountCreate',
    component: () => import(/* webpackChunkName: "cms/cash-count/create" */ '../views/cms/cash-count/CashCountCreate.vue')
  },
  {
    path: '/cms/cash-count/show/:id',
    name: 'CmsCashCountShow',
    component: () => import(/* webpackChunkName: "cms/cash-count/show" */ '../views/cms/cash-count/CashCountShow.vue')
  },
  {
    path: '/cms/cash-count/update/:id',
    name: 'CmsCashCountUpdate',
    component: () => import(/* webpackChunkName: "cms/cash-count/update" */ '../views/cms/cash-count/CashCountUpdate.vue')
  },

  {
    path: '/cms/inventory',
    name: 'CmsInventory',
    component: () => import(/* webpackChunkName: "cms/inventory" */ '../views/cms/inventory/InventoryOverview.vue')
  },

  {
    path: '/cms/schedule',
    name: 'CmsSchedule',
    component: () => import(/* webpackChunkName: "cms/schedule" */ '../views/cms/schedule/ScheduleOverview.vue')
  },
  {
    path: '/cms/schedule/create',
    name: 'CmsScheduleCreate',
    component: () => import(/* webpackChunkName: "cms/schedule/create" */ '../views/cms/schedule/ScheduleCreate.vue')
  },
      {
    path: '/cms/schedule/show/:id',
    name: 'CmsScheduleShow',
    component: () => import(/* webpackChunkName: "cms/schedule/show" */ '../views/cms/entry/EntryShow.vue')
  },
  {
    path: '/cms/schedule/update/:id',
    name: 'CmsScheduleUpdate',
    component: () => import(/* webpackChunkName: "cms/schedule/create/update" */ '../views/cms/entry/EntryUpdate.vue')
  },
  {
    path: '/cms/schedule/delete/:id',
    name: 'CmsScheduleDelete',
    component: () => import(/* webpackChunkName: "cms/schedule/create/delete" */ '../views/cms/entry/EntryDelete.vue')
  },

  {
    path: '/cms/users',
    name: 'CmsUsers',
    component: () => import(/* webpackChunkName: "cms/users" */ '../views/cms/user/UserOverview.vue')
  },
  {
    path: '/cms/users/create',
    name: 'CmsUserCreate',
    component: () => import(/* webpackChunkName: "cms/users/create" */ '../views/cms/user/UserCreate.vue')
  },
  {
    path: '/cms/users/update/:id',
    name: 'CmsUserUpdate',
    component: () => import(/* webpackChunkName: "cms/users/update" */ '../views/cms/user/UserUpdate.vue')
  },
  {
    path: '/cms/users/delete/:id',
    name: 'CmsUserDelete',
    component: () => import(/* webpackChunkName: "cms/users/delete" */ '../views/cms/user/UserDelete.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
