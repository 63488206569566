<template>
  <b-navbar toggleable="lg" type="dark" sticky>
    <b-navbar-brand href="#">
      <router-link to="/" @click.prevent>
        <b-avatar href="#bar" variant="black" size="5em" src="https://i.imgur.com/ABXCx2t.png"></b-avatar>
      </router-link>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-navbar-nav>
          <b-nav-item href="#">
            <b-button class="border-0 nav-bar-link" variant="dark">
              <router-link class="link-color" to="/menu" @click.prevent>Menu</router-link>
            </b-button>
          </b-nav-item>
          <b-nav-item href="#">
            <b-button class="border-0 nav-bar-link" variant="dark">
              <router-link class="link-color" to="/a-la-carte" @click.prevent>À la Carte</router-link>
            </b-button>
          </b-nav-item>
          <b-nav-item href="#">
            <b-button class="border-0 nav-bar-link" variant="dark">
              <router-link class="link-color" to="/about-us" @click.prevent>About Us</router-link>
            </b-button>
          </b-nav-item>
          <b-nav-item href="#">
            <b-button class="border-0 nav-bar-link" variant="dark">
              <router-link class="link-color" to="/visit-us" @click.prevent>Visit Us</router-link>
            </b-button>
          </b-nav-item>
        </b-navbar-nav>

<!--        <b-nav-item-dropdown right>-->
<!--          <template v-slot:button-content>-->
<!--          </template>-->
<!--          <b-nav-item href="#">-->
<!--            <b-button class="border-0 nav-bar-link">-->
<!--              <router-link class="link-color" to="/visit-us" @click.prevent>Login</router-link>-->
<!--            </b-button>-->
<!--          </b-nav-item>-->
<!--        </b-nav-item-dropdown>-->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'isLogged'
    ])
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style>
  .nav-bar-link {
    font-weight: bold;
    background-color: #121521;
  }

  .link-color {
    color: #CBA661;
  }
</style>