  <template>
  <div class="home">
    <Parallax></Parallax>
  </div>
</template>

<script>
import Parallax from '@/components/Parallax.vue'

export default {
  name: 'Home',
  components: {
    Parallax
  },
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s ← Batavia Amsterdam',
    meta: [
      {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'Home base for delicious Indonesian dishes'}
    ],
    link: [
      {rel: 'canonical', href: 'https://www.bataviamsterdam.com/'}
    ]
  }
}
</script>
